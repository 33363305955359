import React, { PropsWithChildren, ReactNode } from "react";

import "./App.css";
import { EmptyProps } from "./types/EmptyProps";
import { IbanValidationResult } from "./types/IbanValidationResult";
import { IbanValidationState } from "./types/IbanValidationState";

import axios from "axios";

import useMediaQuery from "@mui/material/useMediaQuery";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { TextField, Box, Button, Grid, Typography } from "@mui/material";
import { Container, Theme } from "@mui/system";
import DoneIcon from "@mui/icons-material/Done";
import { ResultCard } from "./ResultCard";

class App extends React.Component<EmptyProps, IbanValidationState> {
  constructor(props: EmptyProps) {
    super(props);
    this.state = { iban: "" };

    this.checkIban = this.checkIban.bind(this);
    this.handleIbanChange = this.handleIbanChange.bind(this);
  }

  checkIban(event: React.FormEvent<HTMLFormElement>): void {
    const config = {
      headers: {
        "Content-Type": "text/plain;charset=UTF-8",
      },
    };

    axios
      .post<IbanValidationResult>(
        `${process.env.REACT_APP_API_BASE_URL}` + "/api/validation",
        this.state.iban,
        config
      )
      .then((response) => {
        this.setState({
          ibanValidationResult: response.data,
        });
      });
    event.preventDefault();
  }

  handleIbanChange(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ iban: event.target.value });
  }

  render(): ReactNode {
    return (
      <ThemedApp>
        <Container maxWidth="sm">
          <Box sx={{ my: 5 }}>
            <Typography variant="h4" component="h1" gutterBottom>
              IBAN Validator
            </Typography>
            <Grid
              component="form"
              onSubmit={this.checkIban}
              container
              spacing={2}
              sx={{ my: -1 }}
            >
              <Grid item xs={5}>
                <TextField
                  id="iban"
                  label="IBAN"
                  variant="outlined"
                  value={this.state.iban}
                  onChange={this.handleIbanChange}
                />
              </Grid>
              <Grid item xs={7}>
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  endIcon={<DoneIcon />}
                  sx={{ height: "100%" }}
                >
                  Check
                </Button>
              </Grid>
            </Grid>
            <Box sx={{ my: 1 }}>
              <ResultCard result={this.state.ibanValidationResult} />
            </Box>
          </Box>
        </Container>
      </ThemedApp>
    );
  }
}

function ThemedApp(props: PropsWithChildren): JSX.Element {
  const prefersDarkMode: boolean = useMediaQuery(
    "(prefers-color-scheme: dark)"
  );
  const theme: Theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: prefersDarkMode ? "dark" : "light",
        },
      }),
    [prefersDarkMode]
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {props.children}
    </ThemeProvider>
  );
}

export default App;
