import { CheckCircle, Error } from "@mui/icons-material";
import { Card, CardContent, Typography } from "@mui/material";
import React, { ReactNode } from "react";
import { IbanValidationResult } from "./types/IbanValidationResult";

interface ResultProps {
  result?: IbanValidationResult;
}

function ResultView(props: ResultProps): JSX.Element | null {
  if (!props.result) return null;
  if (props.result.valid)
    return (
      <div>
        <CheckCircle color="success" fontSize="large" />
        <Typography variant="h5" component="div">
          Valid!
        </Typography>
        <Typography variant="body1">
          Bank Code: {props.result.bankCode}
          <br />
          Bank Name: {props.result.bankName}
        </Typography>
      </div>
    );
  else
    return (
      <div>
        <Error sx={{ color: "red" }} fontSize="large" />
        <Typography variant="h5" component="div">
          Invalid!
        </Typography>
      </div>
    );
}

export class ResultCard extends React.Component<
  ResultProps,
  IbanValidationResult
> {
  render(): ReactNode {
    if (!this.props.result) return null;

    return (
      <Card sx={{ minWidth: 275 }}>
        <CardContent>
          <ResultView result={this.props.result} />
        </CardContent>
      </Card>
    );
  }
}
